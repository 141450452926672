.welcome {
    background-color: #F6F6F6;
    color: #000000;
    .banner {
        height: 43px;
        line-height: 43px;
        display: flex;
        align-items: center;
        ion-icon {
            color: white;
            margin-left: 4px;
        }
    }
    .content {
        flex: 1 0;
        position: relative;
        height: 100%;
        width: 45%;
        margin: 0 auto;
        background: white;
        overflow-y: auto;
        > div {
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }
        .more-options {
            text-align: right;
            padding: 10px 20px 10px 0;
            text-decoration: none;
            cursor: pointer;
        }
        .logo {
            padding: 40px 0;
            img {
                max-width: 128px;
            }
        }
        .text {
            text-align: left;
            width: 93%;
            font-size: 24px;
            line-height: 1.5em;
        }
        .inputField {
            width: 93%;
            font-size: 20px;
            line-height: 1.5em;
            margin: 20px auto 5px auto;
            input {
                background-color: white; // override default bg color in case user set their iphone to dark mode.
                border: 1px solid #959595;
                border-radius: 7px;
                padding: 10px;
                width: 100%;
            }
            &.demourl {
                display: flex;
                align-items: center;
                input {
                    flex: 1 0;
                }
            }
        }
        .button-section {
            width: 93%;
            margin: 20px auto;
            button {
                width: 100%;
                background: transparent;
                height: 47px;
                border-radius: 10px;
                border: 1px solid;
                font-size: 20px;
            }
        }

        .note {
            font-size: 14px;
            &.disabled {
                margin: 40px auto;
                color: gray;
                opacity: .5;
            }
        }
    }
}

@media only screen 
and (max-width : 680px) {
    .welcome {
        .content {
            width: 100%;
        }
    }
}