
:global(body) {
    background-color: white !important;
}

.pullpath-loading {
    .loading-wrapper {
        border-radius: 10px;
    }
}

.pullpath-alert, .pullpath-error {
    .alert-wrapper {
        .alert-button-group {
            button {
                flex: 1;
                span {
                    justify-content: center;
                }
            }
        }
    }
}

.pullpath-confirm {
    .alert-wrapper {
        .alert-button-group {
            button {
                flex: 1;
                span {
                    text-align: center;
                    justify-content: center;
                }
            }
        }
    }
}